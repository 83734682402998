<template>
  <div v-if="loaded && !parseInt($store.state.user.uid)" class="page px-4 px-md-8 py-4 py-md-10">
    <v-row no-gutters>
      <v-col cols="12" class="col-md-7 px-md-4">
        <v-card v-if="memberData" class="accent px-4 text-center pb-6 mb-6">
          <v-card-title class="subtitle-2">
            Member Card
          </v-card-title>
          <v-card-text>
            <div class="member-card d-flex align-center" :class="[memberCard ? 'show' : '']" @click.prevent="openMemberCard()">
              <img src="/new-logo-ebrightskin.png">
              <div class="qr">
                <div id="qrcode" />
              </div>
              <div class="member-card-content">
                <div class="name">
                  {{ memberData.name }}
                </div>
                <div class="id">
                  {{ $GEN_ID(memberData) }}
                </div>
                <div class="address">
                  {{ memberData.area_name || (memberData.role + ' ' + memberData.city_name + ', ' + memberData.province_name) }}
                </div>
              </div>
              <div class="content">
                <div class="d-flex align-center justify-end">
                  <div class="d-flex align-center ig">
                    <img src="/images/ic-ig-dark.png"> <span class="primary--text">{{ memberData.instagram }}</span>
                  </div>
                  <div class="d-flex align-center wa">
                    <img src="/images/ic-wa-dark.png"> <span class="primary--text">{{ $phone(memberData.phone) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-title class="caption font-weight-bold pb-0">
            Lead Information
          </v-card-title>
          <v-card-text v-if="memberData.lead_name || memberData.lead_phone || memberData.lead_instagram">
            <v-row v-if="memberData.lead_name" no-gutters class="text-left caption-small">
              <v-col cols="4">
                Name
              </v-col>
              <v-col cols="8">
                : {{ memberData.lead_name }}
              </v-col>
            </v-row>
            <v-row v-if="memberData.lead_phone" no-gutters class="text-left">
              <v-col cols="4">
                Whatsapp
              </v-col>
              <v-col cols="8">
                : <v-btn :href="'https://api.whatsapp.com/send?phone=' + $phone(memberData.lead_phone) + '&text=' + encodeURIComponent('Hi kak ' + memberData.lead_name + ', mau pesan produk eBright Skin dong..')" x-small color="primary" outlined class="my-1 mr-1 caption text-capitalize px-1" target="_blank">
                  <v-icon class="caption">
                    mdi-whatsapp
                  </v-icon>
                  <!-- <div class="caption" style="padding-bottom: 4px;">Whatsapp</div> -->
                </v-btn> {{ $phone(memberData.lead_phone) }}
              </v-col>
            </v-row>
            <v-row v-if="memberData.lead_instagram" no-gutters class="text-left">
              <v-col cols="4">
                Instagram
              </v-col>
              <v-col cols="8">
                : <v-btn :href="'https://instagram.com/' + memberData.lead_instagram" x-small color="primary" outlined class="my-1 mr-1 caption text-capitalize px-1" target="_blank">
                  <v-icon class="caption">
                    mdi-instagram
                  </v-icon>
                  <!-- <div class="caption" style="padding-bottom: 4px;">Whatsapp</div> -->
                </v-btn> @{{ memberData.lead_instagram || '-' }}
              </v-col>
            </v-row>
            <v-row v-if="memberData.lead_city_name" no-gutters class="text-left">
              <v-col cols="4">
                City Location
              </v-col>
              <v-col cols="8">
                : {{ memberData.lead_city_name || '-' }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-row no-gutters class="text-left caption-small">
              <v-col cols="4">
                No information
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="accent px-4 text-center pt-8">
          <v-card-title class="caption font-weight-bold">
            Bank Account Setting
          </v-card-title>
          <v-card-text>
            <v-row v-for="(p, iP) in Payments" :key="'bank-' + iP" no-gutters class="pb-4 bank-setting">
              <v-col cols="12" class="pb-4">
                <v-text-field
                  v-model="Payments[iP].bank_account_name"
                  :name="Math.random()"
                  label="Account Name"
                  :readonly="!p.edit"
                  outlined
                  dense
                  hide-details
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                  autocomplete="disabled"
                />
              </v-col>
              <v-col cols="12" class="col-md-3 mb-3 mb-md-0">
                <v-text-field
                  v-model="Payments[iP].bank_name"
                  :name="Math.random()"
                  label="Bank Name"
                  :readonly="!p.edit"
                  outlined
                  dense
                  autocomplete="disabled"
                  small
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                  hide-details
                />
              </v-col>
              <v-col cols="12" class="col-md-6 pl-md-2 mb-3 mb-md-0">
                <v-text-field
                  v-model="Payments[iP].bank_account_number"
                  :name="Math.random()"
                  label="Account Number"
                  :readonly="!p.edit"
                  outlined
                  dense
                  hide-details
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                  autocomplete="disabled"
                />
              </v-col>
              <v-col v-if="p.edit" cols="12" class="col-md-3 pt-1 text-right text-md-center">
                <v-btn depressed x-small fab class="mr-1" @click.prevent="ProcessBank(Payments[iP], iP)">
                  <v-icon color="green">
                    mdi-check
                  </v-icon>
                </v-btn>
                <v-btn depressed x-small fab class="ml-1" @click.prevent="RemoveBank(p)">
                  <v-icon color="red">
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col v-else cols="12" class="col-md-3 pt-1 text-right text-md-center">
                <v-btn text color="orange" class="mr-1" @click.prevent="Payments[iP].edit = true">
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-6 mb-2" />
              </v-col>
            </v-row>
            <v-row v-if="newBank.show" class="align-center" no-gutters>
              <v-col cols="12" class="pb-4">
                <v-text-field
                  v-model="newBank.data.bank_account_name"
                  :name="Math.random()"
                  label="Account Name"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                />
              </v-col>
              <v-col cols="12" class="col-md-3 mb-3 mb-md-0">
                <v-text-field
                  v-model="newBank.data.bank_name"
                  :name="Math.random()"
                  label="Bank Name"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                />
              </v-col>
              <v-col cols="12" class="col-md-6 pl-md-2 mb-3 mb-md-0">
                <v-text-field
                  v-model="newBank.data.bank_account_number"
                  :name="Math.random()"
                  label="Account Number"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                />
              </v-col>
              <v-col cols="12" class="col-md-3 text-center">
                <v-btn outlined class="text-capitalize" @click.prevent="ProcessBank()">
                  <span class="caption-small">
                    + Add
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="text-center pb-4">
                <v-btn v-if="newBank.show" text small class="text-capitalize pb-1 mt-4" @click.prevent="newBank.show = false">
                  <span class="caption-small">
                    Cancel
                  </span>
                </v-btn>
                <v-btn v-else text small class="text-capitalize pb-1" @click.prevent="newBank.show = true">
                  <span class="caption-small">
                    + Add New Bank
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="parseInt($store.state.user.uid) === 0" class="accent px-4 text-center pt-8 mt-6">
          <v-card-title class="caption font-weight-bold">
            Permission Managers - Sub-Account
          </v-card-title>
          <v-card-text class="pb-8">
            <v-row class="align-center mb-4" no-gutters>
              <v-col cols="12" class="col-md-6 pr-md-1 mb-3 mb-md-0 my-sm-1">
                <v-text-field
                  v-model="NewUserAdmin.name"
                  :name="Math.random()"
                  label="Name"
                  outlined
                  dense
                  hide-details
                  autocomplete="disabled"
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                />
              </v-col>
              <v-col cols="12" class="col-md-6 px-md-1 mb-3 mb-md-0 my-sm-1">
                <v-text-field
                  v-model="NewUserAdmin.email"
                  :name="Math.random()"
                  label="Email"
                  outlined
                  dense
                  hide-details
                  autocomplete="disabled"
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                />
              </v-col>
              <v-col cols="12" class="col-md-6 pr-md-1 mb-3 mb-md-0 my-sm-1">
                <v-text-field
                  v-model="NewUserAdmin.pin"
                  :name="Math.random()"
                  label="Password"
                  type="password"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                />
              </v-col>
              <v-col cols="12" class="col-md-6 px-md-1 mb-3 mb-md-0 my-sm-1">
                <v-select
                  v-model="NewUserAdmin.roles"
                  :items="$store.state.user_roles"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                  label="Role Access"
                  class="accent"
                />
              </v-col>
              <v-col cols="12" class="my-3 mb-md-0">
                <v-btn depressed block color="primary" class="rounded-sm text-capitalize" @click.prevent="ProcessUserAdmin()">
                  <v-icon small class="caption-small mr-1">
                    mdi-plus
                  </v-icon>
                  {{ NewUserAdmin.id ? 'Update' : 'Create' }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!$store.state.user_admin.length" no-gutters class="py-4 bank-setting">
              <v-col cols="12" class="pb-4">
                empty
              </v-col>
            </v-row>
            <v-row v-for="(p, iP) in $store.state.user_admin" :key="'uadmin-' + iP" no-gutters class="pt-2 bank-setting">
              <v-col cols="12" class="d-flex f-width align-center">
                <!-- <v-btn color="red" icon x-small class="mr-1" @click.prevent="RemoveUserAdmin(p)">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn> -->
                <v-card class="d-flex text-left justify-space-between py-2 px-4 elevation-1 fill-width" style="text-transform: unset;" @click.prevent="ShowUserAdmin(p)">
                  <span style="max-width:60%;">
                    <strong class="body-2">{{ p.name }} - {{ p.email }}</strong>
                    <!-- <div class="caption grey--text text--darken-2">
                      <span v-for="(pr, iPr) in p.roles" :key="'uid-' + iP + '-' + iPr">
                        {{ pr }},
                      </span>
                    </div> -->
                  </span>
                  <v-chip small>
                    <template v-if="p.roles.length">
                      <span v-if="$store.state.user_roles.find(r => $ArrayToStr(r.value) === $ArrayToStr(p.roles))">
                        {{ $store.state.user_roles.find(r => $ArrayToStr(r.value) === $ArrayToStr(p.roles)).name }}
                      </span>
                      <span v-else>
                        Undefined
                      </span>
                    </template>
                    <span v-else>
                      Undefined
                    </span>
                  </v-chip>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="col-md-5 px-md-4 mb-6">
        <v-card class="accent px-4 text-center">
          <v-card-title class="subtitle-1 font-weight-bold">
            <div class="d-inline-block mr-5" style="position: relative;">
              <v-avatar size="75" @click.prevent="ChangeProfileImg()">
                <img
                  alt="user"
                  :src="$store.state.user.img || '/icon.png'"
                  style="cursor: pointer;"
                >
              </v-avatar>
              <span style="position: absolute;bottom: 5px;right:5px;cursor: pointer;" @click.prevent="ChangeProfileImg()">
                <v-icon>
                  mdi-pencil-circle
                </v-icon>
              </span>
            </div>
            Account Setting
          </v-card-title>
        <v-card-text v-if="memberData" class="pb-6">
          <div class="px-0 pt-2 pb-0">
            <v-text-field
              v-model="memberData.name"
              :name="Math.random()"
              label="Name"
              outlined
              dense
              hide-details
              autocomplete="disabled"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 required accent"
            />
            <v-text-field
              v-if="$store.state.user.role === 'pusat'"
              v-model="memberData.area_name"
              :name="Math.random()"
              label="Area Name"
              outlined
              dense
              hide-details
              autocomplete="disabled"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 required accent"
            />
            <v-menu
              ref="menuBirthday"
              v-model="menuBirthday"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="memberData.birthday"
                  label="Birthday date"
                  readonly
                  outlined
                  dense
                  hide-details
                  autocomplete="disabled"
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="mb-4 accent"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="pickerBirthday"
                v-model="memberData.birthday"
                :max="new Date().toISOString().substr(0, 10)"
                min="1970-01-01"
                @change="$refs.menuBirthday ? $refs.menuBirthday.save(memberData.birthday) : false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="memberData.username"
              :name="Math.random()"
              label="Username"
              autocomplete="disabled"
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 required accent"
              @keyup="memberData.username = memberData.username ? memberData.username.toLowerCase().split(' ').join('') : ''"
            />
            <v-text-field
              v-model="memberData.email"
              :name="Math.random()"
              type="email"
              label="Email"
              outlined
              dense
              hide-details
              autocomplete="disabled"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 accent"
              :rules="[$validateEmail(memberData.email)]"
            />
            <v-text-field
              v-model="memberData.phone"
              :name="Math.random()"
              label="Phone / Whatsapp Number"
              outlined
              dense
              hide-details
              autocomplete="disabled"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 accent"
              @keypress="$NumberOnly"
              @keyup="memberData.phone = $reformatPhone(memberData.phone)"
            />
            <v-textarea
              v-if="$store.state.user.role !== 'pusat'"
              v-model="memberData.wa_message"
              :name="Math.random()"
              rows="2"
              label="Wa Message for Web List Member"
              outlined
              dense
              hide-details
              autocomplete="disabled"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 accent"
            />
            <v-text-field
              v-model="memberData.instagram"
              :name="Math.random()"
              autocomplete="disabled"
              label="Instagram Username"
              prefix="@"
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 accent"
              @keyup="memberData.instagram ? memberData.instagram = memberData.instagram.split(' ').join('') : ''"
            />
            <v-autocomplete
              v-model.number="memberData.area"
              :name="Math.random()"
              :search-input.sync="areaquery"
              :items="areas"
              item-value="id"
              item-text="area_name"
              outlined
              dense
              hide-details
              label="District, City, Province (Shipping Origin)"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 accent"
              autocomplete="disabled"
              @change="areaSync()"
            ></v-autocomplete>
            <v-textarea
              v-model="memberData.address"
              :name="Math.random()"
              rows="2"
              label="Full Address"
              outlined
              dense
              hide-details
              autocomplete="disabled"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 accent"
            />
            <v-select
              v-model="memberData.couriers"
              :items="[
                { value: 'ninja', name: 'Ninja' },
                { value: 'jnt', name: 'J&T' },
                { value: 'jne', name: 'JNE' },
                { value: 'pos', name: 'POS' },
                { value: 'sicepat', name: 'SICEPAT' },
                { value: 'ide', name: 'ID Express' },
                { value: 'cod', name: 'COD / PICKUP' }
              ]"
              dense
              item-value="value"
              item-text="name"
              hide-details
              label="Set Couriers"
              multiple
              outlined
              autocomplete="disabled"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 accent"
            ></v-select>
            <v-textarea
              v-model="memberData.invoice_note"
              :name="Math.random()"
              rows="2"
              label="Invoice Notes"
              outlined
              dense
              hide-details
              autocomplete="disabled"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-4 accent"
            />
            <v-text-field
              v-if="parseInt($store.state.user.uid) === 0"
              v-model="memberData.password"
              :name="Math.random()"
              type="password"
              :label="memberData.id ? 'Change Password' : 'Create Password'"
              outlined
              dense
              autocomplete="new-password"
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-6 accent"
            />
            <v-text-field
              v-if="memberData.password"
              v-model="memberData.password_confirm"
              :name="Math.random()"
              type="password"
              label="Confirm New Password"
              outlined
              dense
              autocomplete="new-password"
              hide-details
              :rules="[memberData.password === memberData.password_confirm]"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="mb-6 required accent"
            />
            <v-btn depressed small dark class="mb-2 mt-2 orange darken-2 rounded-xl text-capitalize" @click="MEMBER_PROCESS">
              Update
            </v-btn>
          </div>
        </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      scrollable
      max-width="550"
      light
    >
      <v-card v-if="memberData">
        <v-card-title
          class="caption justify-center py-3 subtitle-1"
        >
          Member Card
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="dialog = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-8 pt-8 pb-8 d-flex justify-center">
          <div style="position: relative;width: 100%;overflow-x: auto;">
            <div
              id="qrcodelargearea"
              class="member-card d-flex align-center large"
              :class="[memberCardLarge ? 'show' : '']"
            >
              <img src="/new-logo-ebrightskin.png">
              <div class="qr">
                <div id="qrcodelarge" />
              </div>
              <div class="member-card-content">
                <div class="name">
                  {{ memberData.name }}
                </div>
                <div class="id">
                  {{ $GEN_ID(memberData) }}
                </div>
                <div class="address" contenteditable="true" @click.stop>
                  {{ memberData.area_name || (memberData.role + ' ' + memberData.city_name + ', ' + memberData.province_name) }}
                </div>
              </div>
              <div class="content">
                <div class="d-flex align-center justify-end">
                  <div class="d-flex align-center ig">
                    <img src="/images/ic-ig-dark.png"> <span class="primary--text">{{ memberData.instagram }}</span>
                  </div>
                  <div class="d-flex align-center wa">
                    <img src="/images/ic-wa-dark.png"> <span class="primary--text">{{ $phone(memberData.phone) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end px-4 px-md-8">
          <v-spacer />
          <v-btn
            depressed
            dark
            class="text-capitalize"
            color="blue"
            @click="DownloadMemberCard()"
          >
            Download
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let sTimeout = null
let searchAreaTimeout = null
export default {
  data: () => ({
    dialog: false,
    memberData: null,
    provinces: [],
    cities: [],
    districts: [],
    Payments: [],
    newBank: {
      show: false,
      data: {
        id: null,
        user: 0,
        bank_name: '',
        bank_account_name: '',
        bank_account_number: ''
      }
    },
    memberCard: true,
    memberCardLarge: true,
    menuBirthday: false,
    areaquery: '',
    areas: [],
    loaded: false,
    NewUserAdmin: {
      id: null,
      name: '',
      email: '',
      pin: '',
      roles: [],
      role: 'admin'
    }
  }),
  watch: {
    areaquery: function (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areas.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaTimeout) {
        clearTimeout(searchAreaTimeout)
      }
      searchAreaTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchAreaTimeout)
      }, 300)
    },
    menuBirthday (val) {
      val && setTimeout(() => (this.$refs.pickerBirthday.activePicker = 'YEAR'))
    },
    dialog: function (v) {
      if (v) {
        setTimeout(() => {
          this.MEMBER_QR(this.memberData, 'qrcodelarge', 130)
        }, 300)
      } else {
        this.MEMBER_QR(this.memberData)
      }
    }
  },
  mounted () {
    this.loaded = false
    this.memberData = Object.assign({}, this.$store.state.user)
    this.memberData.password_confirm = ''
    this.memberData.birthday = this.memberData.birthday ? this.$localDT(this.memberData.birthday, 'datedefault') : null
    this.memberData.wa_message = this.$stringToEmoticon(this.memberData.wa_message, this.$store.state.emoticons.emoticons)
    this.memberData.couriers = this.memberData.couriers ? this.$StrToArray(this.memberData.couriers) : []
    this.areaGet('?q=', this.memberData.area)
    this.$store.dispatch('SHIPPING_GET', '?province_only=true').then((res) => {
      if (res.status) {
        this.provinces = res.data.data
        this.loadCities()
      }
    })
    this.loadBank()
    this.MEMBER_QR(this.memberData)
    setTimeout(() => {
      this.loaded = true
    }, 300)
  },
  methods: {
    RemoveUserAdmin (admin) {
      if (!confirm('Remove admin ?')) {
        return false
      }
      this.ProcessUserAdmin(admin.id)
    },
    ShowUserAdmin (adm) {
      this.NewUserAdmin.id = adm.id
      this.NewUserAdmin.name = adm.name
      this.NewUserAdmin.email = adm.email
      this.NewUserAdmin.pin = adm.pin
      this.NewUserAdmin.roles = typeof adm.roles === 'string' ? this.$StrToArray(adm.roles) : adm.roles || []
      this.NewUserAdmin.role = 'admin'
    },
    ProcessUserAdmin (removedID) {
      if ((this.NewUserAdmin.name && this.NewUserAdmin.email) || removedID) {
        const params = Object.assign({ status: 1 }, this.NewUserAdmin)
        params.roles = params.roles ? this.$ArrayToStr(params.roles) : params.roles || '[]'
        if (removedID) {
          params.id = removedID
          params.status = 99
        }
        this.$store.dispatch('ADMIN_PROCESS', params).then((res) => {
          if (res.status) {
            this.$store.commit('SNACKBAR', { show: true, text: (removedID ? 'Admin removed!' : params.id ? 'Admin Updated' : 'Admin created!') })
            this.NewUserAdmin.id = null
            this.NewUserAdmin.name = ''
            this.NewUserAdmin.email = ''
            this.NewUserAdmin.pin = ''
            this.NewUserAdmin.roles = []
            this.NewUserAdmin.role = 'admin'
          }
          this.$store.dispatch('ADMIN_GET')
        })
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'Incomplete input!' })
        return false
      }
    },
    areaSync () {
      const id = parseInt(this.memberData.area)
      if (id) {
        const findSelectedArea = this.areas.find(r => parseInt(r.id) === parseInt(id))
        if (findSelectedArea) {
          this.memberData.district = parseInt(findSelectedArea.kecamatan_id) || 0
          this.memberData.city = parseInt(findSelectedArea.city_id) || 0
          this.memberData.province = parseInt(findSelectedArea.province_id) || 0
        }
      }
    },
    areaGet (q, id) {
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '')).then(res => {
        if (res.status) {
          this.areas = res.data.data || []
        }
      })
    },
    openMemberCard () {
      this.dialog = true
    },
    MEMBER_QR (member, el, size) {
      const qr = this.$Qr()
      qr.qr.update({
        data: 'https://ebrightskin.id/' + this.$GEN_ID(member),
        image: '/icon.png',
        width: size || 85,
        height: size || 85
      })
      setTimeout(() => {
        qr.qr.append(document.getElementById(el || 'qrcode'))
      }, 200)
    },
    DownloadMemberCard () {
      this.$Capture(document.querySelector('#qrcodelargearea')).then((canvas) => {
        const link = document.createElement('a')
        link.setAttribute('download', 'member-card-' + this.memberData.id + '.png')
        link.setAttribute('href', canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
        link.click()
        setTimeout(() => {
          this.dialog = false
        }, 200)
      })
    },
    MEMBER_PROCESS () {
      const member = Object.assign({}, this.memberData)
      if (!member.name) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Name is empty' })
        return false
      }
      if (!member.area_name) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Area name is empty' })
        return false
      }
      if (!this.$USERNAME_CHECK(member.username)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid Username' })
        return false
      }
      if (!member.email) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Email is empty' })
        return false
      }
      if (!member.phone) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Phone is empty' })
        return false
      }
      if (member.password) {
        if (member.password !== member.password_confirm) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Confirm password not match' })
          return false
        }
        if (!confirm('Change password ?')) {
          return false
        }
      }
      if (member.wa_message) {
        member.wa_message = this.$emoticonToString(member.wa_message, this.$store.state.emoticons.emoticons)
      }
      member.couriers = this.$ArrayToStr(this.memberData.couriers)
      this.$store.dispatch('MEMBER_UPDATE', member).then((res) => {
        if (res.status) {
          if (res.data.data === 'username_exist') {
            this.$store.commit('SNACKBAR', { show: true, text: 'Username already taken!' })
          } else if (res.data.data === 'email_exist') {
            this.$store.commit('SNACKBAR', { show: true, text: 'Email already exist' })
          } else if (res.data.data === 'phone_exist') {
            this.$store.commit('SNACKBAR', { show: true, text: 'Phone number already exist' })
          } else if (res.data.data === 'email_phone_required') {
            this.$store.commit('SNACKBAR', { show: true, text: 'Empty email or phone number' })
          } else {
            this.memberData.password = ''
            this.memberData.password_confirm = ''
            this.$store.dispatch('ME')
            this.$store.commit('SNACKBAR', { show: true, text: 'Account updated' })
          }
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    loadCities () {
      if (!this.memberData) {
        return false
      }
      if (parseInt(this.memberData.province)) {
        this.$store.dispatch('SHIPPING_GET', '?province=' + this.memberData.province).then((res) => {
          if (res.status) {
            this.cities = res.data.data || []
            this.loadDistricts()
          }
        })
      }
    },
    loadDistricts () {
      if (!this.memberData) {
        return false
      }
      if (parseInt(this.memberData.city)) {
        this.$store.dispatch('SHIPPING_DISTRICT_GET', '?city=' + this.memberData.city).then((res) => {
          if (res.status) {
            this.districts = res.data.data.data || []
          }
        })
      }
    },
    loadBank () {
      this.$store.dispatch('BANK_SELLER_GET', this.$store.state.user.id).then((res) => {
        if (res.status) {
          this.Payments = res.data.data.map((r) => {
            r.edit = false
            return r
          }) || []
        } else {
          this.Payments = []
        }
      })
    },
    ProcessBank (b, index) {
      const bank = Object.assign({}, b || this.newBank.data)
      bank.user = this.$store.state.user.id
      if (!bank.bank_name) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Bank name is empty' })
        return false
      }
      if (!bank.bank_account_name) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Bank account name is empty' })
        return false
      }
      if (!bank.bank_account_number) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Bank account number is empty' })
        return false
      }
      if (parseInt(bank.id)) {
        if (sTimeout) {
          clearTimeout(sTimeout)
        }
        sTimeout = setTimeout(() => {
          this.$store.dispatch('BANK_UPDATE', bank).then((res) => {
            if (res.status) {
              this.loadBank()
              this.$store.commit('SNACKBAR', { show: true, text: 'Updated' })
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
            }
            this.Payments[index].edit = false
          })
        }, 300)
      } else {
        this.$store.dispatch('BANK_ADD', bank).then((res) => {
          if (res.status) {
            this.newBank.show = false
            this.loadBank()
            this.newBank.data.bank_name = ''
            this.newBank.data.bank_account_name = ''
            this.newBank.data.bank_account_number = ''
            this.$store.commit('SNACKBAR', { show: true, text: 'Created' })
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    },
    RemoveBank (bank) {
      if (!confirm('Remove Bank Account ?')) {
        return false
      }
      this.$store.dispatch('BANK_DELETE', bank).then((res) => {
        if (res.status) {
          this.loadBank()
          this.$store.commit('SNACKBAR', { show: true, text: 'Removed' })
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    ChangeProfileImg () {
      const f = document.getElementById('imageUploaderProfile')
      if (f) {
        f.remove()
      }
      const $ = this
      const el = document.createElement('input')
      el.setAttribute('id', 'imageUploaderProfile')
      el.setAttribute('type', 'file')
      el.setAttribute('class', 'file-uploader')
      el.setAttribute('accept', '.jpg,.jpeg,.png')
      el.addEventListener('change', function (e) {
        $.UPLOAD_HANDLER(e)
      })
      document.body.appendChild(el)
      el.click()
    },
    async UPLOAD_HANDLER (event) {
      /* eslint-disable */
      const $ = this
      await $.$imgtoBase64(event, async function (res) {
        if (res.status) {
          // $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            size: 200
          }).then((resUpload) => {
            $.$store.dispatch('MEMBER_UPDATE', {
              id: $.$store.state.user.id,
              img: resUpload.data.data.secure_url
            }).then((r) => {
              $.$store.dispatch('ME').then((rr) => {
                $.memberData = Object.assign({}, $.$store.state.user)
              })
            })
          })
        } else {
          res.data === 'file_size'
            ? $.$store.commit('SNACKBAR', { show: true, message: 'Ops, file image is too large. Max size (images < 5Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.bank-setting {
  .v-input--is-readonly {
    background: rgba(100, 100, 100, .1);
  }
}
</style>
